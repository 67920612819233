// import { useEffect } from "react"
// import img1 from "../../images/pexels-david-bartus-442116.jpg"
// import img2 from "../../images/pexels-grape-things-2954924.jpg"
// // import img3 from "../../images/pexels-pixabay-39511.jpg"
// import img4 from "../../images/pexels-tim-mossholder-953166.jpg"
// import video from "../../videos/6739346-hd_1920_1080_24fps.mp4"
// import { Splide, SplideSlide } from '@splidejs/react-splide';
// // Default theme
// import '@splidejs/react-splide/css';


// // or other themes
// import '@splidejs/react-splide/css/skyblue';
// import '@splidejs/react-splide/css/sea-green';


// // or only core styles
// import '@splidejs/react-splide/css/core';

// export const Slider = () => {

//     useEffect(() => {
//         window.scrollTo({top: 0, left: 0, behavior: 'auto'});
//     }, [])


//     // return (

//     //     <section className="slider">
//     //         <div className="slider-box">
//     //             <div className="slider-content">
//     //                 <div className="slider-img">
//     //                     <img className="slider-image" alt="slider1" src={img2}/>
//     //                     <div className="slider-text">
//     //                         <div className="content-textUno">
//     //                             <p className="content-textEffect">TE TRAEMOS</p>
//     //                         </div>
//     //                         <div className="content-textDos">
//     //                             <p className="content-textEffect delay">Una Experiencia</p>
//     //                         </div>
//     //                     </div>
//     //                 </div>
//     //             </div>
//     //             <div className="slider-content">
//     //                 <div className="slider-img">
//     //                     <img className="slider-image" alt="slider2" src={img4}/>
//     //                     <div className="slider-text">
//     //                         <div className="content-textUno">
//     //                             <p className="content-textEffect">TE BRINDAMOS VINOS</p>
//     //                         </div>
//     //                         <div className="content-textDos">
//     //                             <p className="content-textEffect delay">con Identidad</p>
//     //                         </div>
//     //                     </div>
//     //                 </div>
//     //             </div>
//     //             <div className="slider-content">
//     //                 <div className="slider-img">
//     //                     {/* <img className="slider-image" alt="slider3" src={img3}/> */}
//     //                     <video className="slider-image" autoPlay loop muted playsInline>
//     //                         <source src={video} type="video/mp4" />
//     //                         Tu navegador no soporta el elemento de video.
//     //                     </video>
//     //                     <div className="slider-text">
//     //                         <div className="content-textUno">
//     //                             <p className="content-textEffect">IMPORTAMOS</p>
//     //                         </div>
//     //                         <div className="content-textDos">
//     //                             <p className="content-textEffect delay">Historia</p>
//     //                         </div>
//     //                     </div>
//     //                 </div>
//     //             </div>
//     //             <div className="slider-content">
//     //                 <div className="slider-img">
//     //                     <img className="slider-image" alt="slider4" src={img1}/>
//     //                     <div className="slider-text">
//     //                         <div className="content-textUno">
//     //                             <p className="content-textEffect">TE COMPARTIMOS</p>
//     //                         </div>
//     //                         <div className="content-textDos">
//     //                             <p className="content-textEffect delay">Cultura y Orígenes</p>
//     //                         </div>
//     //                     </div>
//     //                 </div>
//     //             </div>
//     //         </div>
//     //     </section>
//     // )

//     return (
//         <>
//             <section className="slider">
//                 <Splide 
//                 className='slider_container'
//                 options={{
//                     type: "loop",
//                     perPage:1,
//                     autoplay: true,
//                     pauseOnFocus: false,
//                     pauseOnHover: false,
//                     interval: 4000,
//                     arrows: false,
//                     pagination: false,
//                     rewind:true,
//                 }}
//                 >
//                     <SplideSlide className="slider-img">
//                         <img className="slider-image" alt="slider1" src={img2}/>
//                             <div className="slider-text">
//                                 <div className="content-textUno">
//                                     <p>TE TRAEMOS</p>
//                                 </div>
//                                 <div className="content-textDos">
//                                     <p>Una Experiencia</p>
//                                 </div>
//                             </div>
//                     </SplideSlide>
//                     <SplideSlide className="slider-img">
//                         <img className="slider-image" alt="slider2" src={img4}/>
//                         <div className="slider-text">
//                             <div className="content-textUno">
//                                 <p>TE BRINDAMOS VINO</p>
//                             </div>
//                             <div className="content-textDos">
//                                 <p>Con Identidad</p>
//                             </div>
//                         </div>
//                     </SplideSlide>
//                     <SplideSlide className="slider-img">
//                         <video className="slider-image" autoPlay loop muted playsInline>
//                             <source src={video} type="video/mp4" />
//                             Tu navegador no soporta el elemento de video.
//                         </video>
//                         <div className="slider-text">
//                             <div className="content-textUno">
//                                 <p>IMPORTAMOS</p>
//                             </div>
//                             <div className="content-textDos">
//                                 <p>Historia</p>
//                             </div>
//                         </div>
//                     </SplideSlide>
//                     <SplideSlide className="slider-img">
//                         <img className="slider-image" alt="slider4" src={img1}/>
//                         <div className="slider-text">
//                             <div className="content-textUno">
//                                 <p>TE COMPARTIMOS</p>
//                             </div>
//                             <div className="content-textDos">
//                                 <p>Cultura y Orígenes</p>
//                             </div>
//                         </div>
//                     </SplideSlide>
//                 </Splide>
//             </section>
//         </>
//     )
// }


import { useEffect, useRef } from "react";
import img1 from "../../images/pexels-david-bartus-442116.jpg";
import img2 from "../../images/pexels-grape-things-2954924.jpg";
import img4 from "../../images/pexels-tim-mossholder-953166.jpg";
import video from "../../videos/6739346-hd_1920_1080_24fps.mp4";
import { Splide, SplideSlide } from "@splidejs/react-splide";

import "@splidejs/react-splide/css";
import "@splidejs/react-splide/css/skyblue";

export const Slider = () => {
  const splideRef = useRef(null);

  useEffect(() => {
    // Garantizamos que la página se inicie en la parte superior
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });

    // Verificamos si Splide está montado
    if (splideRef.current) {
      const splide = splideRef.current.splide;

      // Evento para manejar la visibilidad del texto
      const handleVisible = (slide) => {
        const textElement = slide.slide.querySelector(".slider-text");
        if (textElement) {
          textElement.classList.add("active");
        }
      };

      const handleHidden = (slide) => {
        const textElement = slide.slide.querySelector(".slider-text");
        if (textElement) {
          textElement.classList.remove("active");
        }
      };

      // Asignar eventos de visibilidad
      splide.on("visible", handleVisible);
      splide.on("hidden", handleHidden);

      // Activar manualmente el texto de la primera diapositiva
      const firstSlide = splide.Components.Slides.getAt(0);
      if (firstSlide) {
        handleVisible(firstSlide);
      }

      // Limpieza
      return () => {
        splide.off("visible", handleVisible);
        splide.off("hidden", handleHidden);
      };
    }
  }, []);

  return (
    <section className="slider">
      <Splide
        ref={splideRef}
        className="slider_container"
        options={{
          type: "loop",
          perPage: 1,
          autoplay: true,
          pauseOnFocus: false,
          pauseOnHover: false,
          interval: 4000,
          arrows: false,
          pagination: false,
          rewind: true,
        }}
      >
        <SplideSlide className="slider-img">
          <img className="slider-image" alt="slider1" src={img2} />
          <div className="slider-text">
            <div className="content-textUno">
              <p>TE TRAEMOS</p>
            </div>
            <div className="content-textDos">
              <p>Una Experiencia</p>
            </div>
          </div>
        </SplideSlide>
        <SplideSlide className="slider-img">
          <img className="slider-image" alt="slider2" src={img4} />
          <div className="slider-text">
            <div className="content-textUno">
              <p>TE BRINDAMOS VINO</p>
            </div>
            <div className="content-textDos">
              <p>Con Identidad</p>
            </div>
          </div>
        </SplideSlide>
        <SplideSlide className="slider-img">
          <video
            className="slider-image"
            autoPlay
            loop
            muted
            playsInline
          >
            <source src={video} type="video/mp4" />
            Tu navegador no soporta el elemento de video.
          </video>
          <div className="slider-text">
            <div className="content-textUno">
              <p>IMPORTAMOS</p>
            </div>
            <div className="content-textDos">
              <p>Historia</p>
            </div>
          </div>
        </SplideSlide>
        <SplideSlide className="slider-img">
          <img className="slider-image" alt="slider4" src={img1} />
          <div className="slider-text">
            <div className="content-textUno">
              <p>TE COMPARTIMOS</p>
            </div>
            <div className="content-textDos">
              <p>Cultura y Orígenes</p>
            </div>
          </div>
        </SplideSlide>
      </Splide>
    </section>
  );
};
